import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";

import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Client } from "../../../data/models/client";
import { useStore } from "../../../stores/store";
import { Pagination } from "../../../data/models/pagination";
import DropdownMenu from "../../../components/DropdownMenu";
import GenericTable, { SortType } from "../../../components/GenericTable";
import { TableHeader } from "../../../_styles/table/TableHeader";
import { TableText } from "../../../_styles/table/TableText";
import { Container, IconButton, Tooltip } from "@mui/material";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { arrayToString, isUUID } from "../../../utils/utils";
import { CheckIcon } from "../../../_styles/icons/CheckIcon";
import "../styles.css";
import { useTranslation } from "react-i18next";
import { RoundButton } from "../../../_styles/StyledButtons";
import { hasOtherRoles, hasRoles } from "../../../utils/permissionEvaluator";
import ClientRoleFilter from "./ClientRoleFilter";

interface ClientProps {
    onSort?: (key: string, type: SortType) => void;
    onPageChange?: (page: number, pageSize: number) => void;
    onRoleChange: (roleId: number) => void;
}

function ClientList({ onSort, onPageChange, onRoleChange }: ClientProps) {
    const { t } = useTranslation();
    const {
        clientStore: { loading, deleteClient, clientList },
        dialogStore: { openDialog, closeDialog },
        institutionStore: { selectedUserInstitution },
        dataExportStore: { getBridgeTableExport, getConsentFiles },
        loadingStore: { isLoading },
    } = useStore();
    const paginationString = sessionStorage.getItem("client-pagination");
    const paginationParams = paginationString
        ? JSON.parse(paginationString!)
        : null;
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        pageSize: paginationParams?.PageSize ?? 10,
    });
    const maxPage = useRef(pagination.page);
    const navigate = useNavigate();

    let options = [
        {
            id: "view",
            name: t("GENERAL_VIEW_DETAILS"),
            icon: (
                <ManageAccountsIcon
                    sx={{
                        color: "#6ca0b7",
                        position: "absolute",
                        right: 0,
                        paddingRight: 1,
                    }}
                />
            ),
            onClick: (id: string) => navigate(`/users/${id}`),
            customStyle: {
                color: "#6ca0b7",
            },
        },
    ];

    if (hasOtherRoles(['CLIENT', 'HELPER'], selectedUserInstitution?.roles!)) {
        options = [
            ...options,
            {
                id: "edit",
                name: t("GENERAL_EDIT"),
                icon: (
                    <EditIcon
                        sx={{
                            position: "absolute",
                            right: 0,
                            paddingRight: 1,
                            color: "#118ab2",
                        }}
                    />
                ),
                onClick: (id: string) => navigate(`/users/${id}/update`),
                customStyle: {
                    color: "#118ab2",
                },
            },
        ];
    }

    if (selectedUserInstitution?.roles.some((r) => r.toUpperCase() === "ADMIN")) {
        options = [
            ...options,
            {
                id: "delete",
                name: t("GENERAL_DELETE"),
                onClick: (id: string) =>
                    openDialog(
                        t("DIALOG_TITLE_DELETE"),
                        t("DIALOG_MSG_CLIENT"),
                        async () => {
                            await deleteClient(id);
                            closeDialog();
                            toast.success(t("TOAST_CLIENT_DELETE"));
                        },
                        closeDialog
                    ),
                icon: (
                    <DeleteIcon
                        sx={{ position: "absolute", right: 0, paddingRight: 1 }}
                        color="error"
                    />
                ),
                customStyle: {
                    color: "#FF0000",
                },
            },
        ];
    }

    const exportBridgeTable = async () => {
        if (!selectedUserInstitution) return;
        try {
            const data = await getBridgeTableExport(
                selectedUserInstitution.institutionId
            );

            const blob = new Blob([data], { type: "text/csv" });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `result2.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    const exportConsentFiles = async () => {
        if (!selectedUserInstitution) return;
        try { 
            const data = await getConsentFiles(
                selectedUserInstitution.institutionId
            );
            
            const blob = new Blob([data], { type: "application/zip" });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'InformConsents.zip');
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    const handlePageChange = async (page: number, pageSize: number) => {
        setPagination({ page: page + 1, pageSize });
        maxPage.current = page + 1;

        if (onPageChange) {
            onPageChange(page, pageSize);
        }
    };

    return (
        <Container maxWidth="xl">
            <GenericTable<Client>
                columns={[
                    {
                        header: <TableHeader>{t("GENERAL_NAME")}</TableHeader>,
                        key: "fullName",
                        render: (client) => (
                            <TableText>
                                {client.firstName} {client.lastName}
                            </TableText>
                        ),
                        className: "process-cell",
                        sort: (a, b) => {
                            const aFullName = `${a.firstName} ${a.lastName}`;
                            const bFullName = `${b.firstName} ${b.lastName}`;
                            return aFullName
                                .toLowerCase()
                                .localeCompare(bFullName.toLowerCase());
                        },
                    },
                    {
                        header: <TableHeader>{t("USER_EMAIL")}</TableHeader>,
                        key: "email",
                        render: (client) => (
                            <TableText>{isUUID(client.email) ? "" : client.email}</TableText>
                        ),
                        className: "process-cell",
                        sort: (a, b) => {
                            const isAUUID = isUUID(a.email);
                            const isBUUID = isUUID(b.email);

                            if (isAUUID && isBUUID) {
                                return 0;
                            } else if (isAUUID) {
                                return -1;
                            } else if (isBUUID) {
                                return 1;
                            } else {
                                return a.email
                                    .toLowerCase()
                                    .localeCompare(b.email.toLowerCase());
                            }
                        },
                    },
                    {
                        header: <TableHeader>{t("SETTINGS_ROLES_NAME")}</TableHeader>,
                        key: "role",
                        render: (client) => (
                            <TableText
                                title={arrayToString(client.roles.map((r) => r.name))}
                                sx={{ whiteSpace: "normal", overflow: "auto" }}
                            >
                                {arrayToString(client.roles.map((r) => r.name))}
                            </TableText>
                        ),
                        className: "process-cell",
                    },
                    {
                        header: (
                            <TableHeader sx={{ display: "flex", justifyContent: "center" }}>
                                {t("ACC_LABEL")}
                            </TableHeader>
                        ),
                        key: "hasAccount",
                        render: (client) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    ml: "15px",
                                }}
                            >
                                {client.hasAccount && <CheckIcon />}
                            </Box>
                        ),
                        className: "process-cell",
                        width: 10,
                    },
                    {
                        header: <TableHeader></TableHeader>,
                        key: "actions",
                        render: (client) =>
                            client.roles.find((r) => r.name === "Client") ? (
                                <Box mr={2}>
                                    <DropdownMenu
                                        options={options.map((option) => {
                                            return {
                                                ...option,
                                                onClick: () => option.onClick(client.id),
                                            };
                                        })}
                                    />
                                </Box>
                            ) : (
                                <Tooltip
                                    title={t("USER_GO_TO_SETTINGS_TO_EDIT")}
                                    placement="bottom"
                                >
                                    <div style={{ marginRight: "16px" }}>
                                        <IconButton disabled>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            ),
                        className: "client-list-actions",
                    },
                ]}
                rows={clientList || []}
                props={{
                    containerProps: { className: "survey-table-container" },
                    tableContainerProps: { className: "survey-table-container-data" },
                    tableHeadProps: { className: "survey-table-header" },
                    rowProps: { className: "survey-row" },
                }}
                rowCount={paginationParams?.TotalCount ?? clientList.length}
                rowKey="id"
                loading={loading}
                usePagination
                onPaginationModelChange={handlePageChange}
                onSortChange={onSort}
                toolbar={
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ClientRoleFilter onFilterChange={onRoleChange} />
                        {hasRoles(
                            ["Contact Manager"],
                            selectedUserInstitution?.roles ?? [],
                            false
                        ) ? (
                            <RoundButton
                                variant="text"
                                onClick={exportBridgeTable}
                                disabled={isLoading(getBridgeTableExport)}
                                color="info"
                                disableRipple
                                sx={{
                                    textTransform: "none",
                                    fontWeight: 400,
                                    mr: 2,
                                    ml: 2,
                                    '&:hover': {
                                        textDecoration: "underline"
                                    }
                                }}
                            >
                                {isLoading(getBridgeTableExport)
                                    ? "Exporting"
                                    : "Export bridge data table"}
                            </RoundButton>
                        ) : null}
                        {hasRoles(
                            ["Contact Manager"],
                            selectedUserInstitution?.roles ?? [],
                            false
                        ) ? (
                            <RoundButton
                                variant="text"
                                onClick={exportConsentFiles}
                                disabled={isLoading(getConsentFiles)}
                                color="info"
                                disableRipple
                                sx={{
                                    textTransform: "none",
                                    fontWeight: 400,
                                    mr: 2,
                                    ml: 2,
                                    '&:hover': {
                                        textDecoration: "underline"
                                    }
                                }}
                            >
                                {isLoading(getBridgeTableExport)
                                    ? "Exporting"
                                    : "Export clients consent files"}
                            </RoundButton>
                        ) : null}
                    </Box>
                }
            />
            <ConfirmDialog />
        </Container>
    );
}

export default observer(ClientList);

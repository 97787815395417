import { Box, CircularProgress, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useEffect, useState } from "react";

import FormInputDate from "../../../components/form/FormInputDate";
import FormInputTime from "../../../components/form/FormInputTime";
import FormInputSelect from "../../../components/form/FormInputSelect";
import FormInputSwitch from "../../../components/form/FormInputSwitch";
import { RoundButton } from "../../../_styles/StyledButtons";
import { repeatOptions } from "../../../data/static/booking";
import {
    CalendarEvent,
    EventCreateDto,
    Recurrence,
} from "../../../data/models/event";
import { combineDateAndTime } from "../../../utils/utils";
import RecurrenceFormDialog from "../../Booking/Form/RecurrenceForm";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/store";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { clientEventFormSchema } from "../../../_validators/schemas/clientForm.schema";
import { ClientEvent } from "../../../data/models/client";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

interface ClientEventFormProps {
    setEvents: (events: CalendarEvent[]) => void;
}

function ClientEventForm({ setEvents }: ClientEventFormProps) {
    const { t } = useTranslation();
    const { clientStore, bookingStore, institutionStore, tenantStore } =
        useStore();
    const { getClient, _clientCreateId, client } = clientStore;
    const { createClientEvent, getClientEvents, getEventTypes, eventTypes } =
        bookingStore;
    const { id } = useParams();
    const { selectedUserInstitution } = institutionStore;
    const { selectedTenant } = tenantStore;
    const { control, watch, handleSubmit, setValue } = useForm<ClientEvent>({
        defaultValues: {
            startDate: new Date(),
            startTime: dayjs(new Date()),
            endDate: dayjs(new Date()).add(1, "hour").toDate(),
            endTime: dayjs(new Date()).add(1, "hour"),
            isAllDay: false,
            isScreening: false,
        },
        resolver: yupResolver(clientEventFormSchema()),
    });
    const [frequency, setFrequency] = useState(9);
    const startDate = watch("startDate");
    const endDate = watch("endDate");
    const isAllDay = watch("isAllDay");
    const isScreening = watch("isScreening");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (_clientCreateId && selectedUserInstitution) {
                await getClient(_clientCreateId, selectedUserInstitution.institutionId);
                if (id) {
                    const clientEvents = await getClientEvents(_clientCreateId!, false);
                    setEvents(clientEvents.filter(e => e.eventType === "AVAILABLE"));
                }
            }
            if (selectedTenant) {
                await getEventTypes(selectedTenant.id);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_clientCreateId, id, selectedTenant, selectedUserInstitution]);

    useEffect(() => {
        if (startDate > endDate) {
            setValue("endDate", startDate);
        }
    }, [endDate, setValue, startDate]);

    const onFreqSelection = (e: any) => {
        setFrequency(e.target.value);
    };



    const onSubmit = async (data: ClientEvent) => {
        setLoading(true);

        if (data.recurrence) {
            data.recurrence.type = frequency;
            data.recurrence.startDate = combineDateAndTime(
                data.startDate,
                dayjs(data.startTime).toDate(),
            );
        }

        const event: EventCreateDto = {
            start: combineDateAndTime(
                data.startDate,
                dayjs(data.startTime).toDate()
            ),
            end: combineDateAndTime(
                data.endDate,
                dayjs(data.endTime).toDate(),
            ),
            isAllDay: isAllDay,
            isScreening: isScreening,
            recurrence:
                data.recurrence ??
                ({
                    type: 9,
                } as Recurrence),
            roomId: null,
            title: "AVAILABLE",
            eventType: eventTypes.find((e) => e.name === "AVAILABLE")?.id!,
            description: "",
            attendees: [client?.email!],
            reminders: [],
            institutionId: selectedUserInstitution?.institutionId!,
            IsConfirmed: true,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        try {
            await createClientEvent(event);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
        const clientEvents = await getClientEvents(_clientCreateId!, false);
        setEvents(clientEvents.filter(e => e.eventType === "AVAILABLE"));
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnGap={1}>
                <Grid item xs={12} lg={5.8}>
                    <FormInputDate
                        name="startDate"
                        control={control}
                        label={t("EVENT_START_DATE")}
                    />
                </Grid>
                <Grid item xs={12} lg={5.8}>
                    <FormInputTime
                        name="startTime"
                        control={control}
                        label={t("EVENT_START_TIME")}
                        disabled={isAllDay}
                    />
                </Grid>
                <Grid item xs={12} lg={5.8}>
                    <FormInputDate name="endDate" control={control} label={t("EVENT_END_DATE")} />
                </Grid>
                <Grid item xs={12} lg={5.8}>
                    <FormInputTime
                        name="endTime"
                        control={control}
                        label={t("EVENT_END_TIME")}
                        disabled={isAllDay}
                    />
                </Grid>
                <Grid item xs={12} lg={5.8}>
                    <FormInputSelect
                        name="frequency"
                        control={control}
                        label={t("EVENT_RECURRENCE")}
                        defaultValue={9}
                        options={repeatOptions}
                        onSelect={onFreqSelection}
                        labelKey={"name"}
                        valueKey={"id"}
                    />
                </Grid>
                <Grid item xs={12} lg={2.5} display={"flex"}>
                    <FormInputSwitch
                        name="isAllDay"
                        label={t("EVENT_ALL_DAY")}
                        control={control}
                        defaultValue={false}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={3.3}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <RoundButton type="submit" variant="contained" sx={{ width: "auto" }} disabled={loading}>
                        {loading ? (
                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                        ) : (
                            <AddIcon />
                        )}
                    </RoundButton>
                </Grid>
            </Grid>
            <RecurrenceFormDialog
                control={control}
                initialEndDate={endDate}
                frequency={frequency}
            />
        </Box>
    );
}

export default observer(ClientEventForm);

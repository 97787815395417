/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, CircularProgress, FormHelperText, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { LinkNode } from '@lexical/link';

import './Editor/Plugin/editorStyle.css';
import { RoundButton } from "../../_styles/StyledButtons";
import { FormInputText } from "../../components/form/FormInputText";
import { useStore } from "../../stores/store";
import { TemplateContentDto, TemplateInstanceDto } from "../../data/models/notification";
import { editorFormSchema } from "../../_validators/schemas/editorForm.schema";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputCheckbox from "../../components/form/FormInputCheckbox";
import { templateContentForCreateResolver, templateContentForUpdateResolver, uniqueTemplateName } from "../../utils/helpers/editorHelper";
import { useTranslation } from "react-i18next";
import Editor from "./Editor/Editor";

interface TemplateDashboardProps {
    selectedInstance: TemplateInstanceDto,
    templateLanguage: string,
    selectedTemplateId?: string
}

function onError(error: Error): void {
    console.error('error: ', error);
    throw error;
}

const TemplateDashboard = ({ selectedInstance, templateLanguage, selectedTemplateId }: TemplateDashboardProps) => {
    const { t } = useTranslation();
    const [isCopy, setIsCopy] = useState<boolean>(false);
    const defaultValues = {
        id: '',
        title: '',
        subject: '',
        body: '',
        commChannel: '',
        study: '',
        templateInstanceId: '',
        isAllChannels: false,
        isAllProjects: false,
        isBlocked: false
    }
    const {
        handleSubmit,
        control,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm<TemplateContentDto>({
        defaultValues,
        resolver: yupResolver(editorFormSchema())
    });
    const {
        templateStore,
        institutionStore: { selectedUserInstitution },
        loadingStore: { isLoading }
    } = useStore();

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('md'));

    const study = watch('study');
    const channel = watch('commChannel');

    const initialConfig = {
        namespace: 'LexicalEditor',
        theme: {},
        nodes: [
            LinkNode,
        ],
        onError,
    };

    const projects = [
        { value: 'BRIGHT', label: 'BRIGHT' },
        { value: 'SPIRIT', label: 'SPIRIT' }
    ];

    const handleContent = async () => {
        if (templateStore.contentList) {
            const content = templateStore.contentList.find(c => c.study === study && c.commChannel === channel)
            templateStore.setContent(content?.body || '');
            setValue('subject', content?.subject || '')
            setValue('id', content?.id || '')
        }
    }

    const handleProjectSelection = (item: any) => {
        if (item) {
            setValue('study', item.target.value);
        }
    }

    const handleChannelSelection = (item: any) => {
        if (item) {
            setValue('commChannel', item.target.value);
        }
    }

    const onSubmit = async (data: TemplateContentDto) => {
        if (isCopy) {
            data.title = uniqueTemplateName(data.title as string, templateStore.templateInstanceList);
            const templateContent = templateContentForCreateResolver(data, templateLanguage, selectedUserInstitution?.institutionId!, selectedInstance.templateDefinitionId, templateStore.contentList, templateStore.hasAllChannels);
            await templateStore.createTemplate(templateContent?.templateDefinitionId!, templateContent);
        }
        else {
            const templateContent = templateContentForUpdateResolver(data, templateLanguage, selectedUserInstitution?.institutionId!, selectedInstance.id, templateStore.hasAllChannels);
            await templateStore.updateTemplate(selectedInstance.id, templateContent);
        }

        toast.success(t('TOAST_TEMPLATE_SAVED'));
        await templateStore.getTemplateInstances(selectedTemplateId!, selectedUserInstitution?.institutionId!, templateLanguage);
        await templateStore.getTemplateContent(selectedInstance.id);
        await handleContent();
    };

    useEffect(() => {
        if (selectedInstance) {
            templateStore.getTemplateContent(selectedInstance.id);
            setValue('title', selectedInstance.title)
            setValue('study', '')
            setValue('commChannel', '')
            setValue('isAllChannels', false)
            setValue('isAllProjects', false)
            setValue('isBlocked', false)
            setValue('body', '');
            clearErrors();
        }
    }, [selectedInstance]);

    useEffect(() => {
        handleContent();
    }, [study, channel])

    return (
        selectedTemplateId ?
            <Grid item
                md={selectedInstance ? 8 : 0}
                lg={selectedInstance ? 8 : 0}
                xl={selectedInstance ? 8.7 : 0}
                sx={{
                    width: selectedInstance ? '100%' : '0',
                    transition: 'width 0.5s ease-in-out',
                    overflow: 'hidden',
                    display: selectedInstance ? 'block' : 'none',
                }}>
                <Box className="templates-preview-content">
                    <Paper
                        component="form"
                        sx={{ display: "block", margin: 5, backgroundColor: "#ffffff", width: '100%' }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container direction={'column'} sx={{ padding: 3 }} xs>
                            <Grid item sx={{ flexDirection: isXs ? 'column' : 'row', display: 'flex', gap: 2 }}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <FormInputSelect
                                        control={control}
                                        name="study"
                                        label={t('TEMPLATE_SELECT_PROJECT')}
                                        options={projects}
                                        labelKey={'label'}
                                        valueKey={'value'}
                                        onSelect={(handleProjectSelection)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <FormInputSelect
                                        control={control}
                                        name="commChannel"
                                        label={t('TEMPLATE_SELECT_CHANNEL')}
                                        options={templateStore.templateCommChannels}
                                        labelKey={'name'}
                                        valueKey={'value'}
                                        onSelect={(handleChannelSelection)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sx={{ flexDirection: isXs ? 'column' : 'row', display: 'flex', gap: 2 }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormInputText
                                        control={control}
                                        name="title"
                                        label={`${t('ACC_TITLE')} *`}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormInputText
                                        control={control}
                                        name="subject"
                                        label={`${t('GENERAL_SUBJECT')} ${channel === 'EMAIL' ? '*' : ''}`}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sx={{ paddingBottom: 5, flexDirection: isXs ? 'column' : 'row', display: 'flex', gap: 2 }}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
                                    <Controller
                                        name='body'
                                        control={control}
                                        render={({ field }) => (<>
                                            <LexicalComposer initialConfig={initialConfig}>
                                                <Editor field={field} setValue={setValue} />
                                            </LexicalComposer>
                                            {errors.body && <FormHelperText error>{errors.body.message}</FormHelperText>}
                                        </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={2} xl={6}>
                                    <Typography>{t("TEMPLATE_AVAILABLE_VARIABLES")}:</Typography>
                                    {
                                        selectedInstance && selectedInstance!.availableVariables!.map((variable) => (
                                            <Typography fontSize={"14px"}>{`{${variable}}`}</Typography>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item sx={{ flexDirection: 'row', display: 'flex' }}>
                                <Grid item xs={6} md={3} lg={3} xl={3} style={{
                                    opacity: !templateStore.hasAllChannels ? 0.5 : 1,
                                    pointerEvents: !templateStore.hasAllChannels ? 'none' : 'auto'
                                }}>
                                    <FormInputCheckbox
                                        name="isAllChannels"
                                        control={control}
                                        label={t('TEMPLATE_SAVE_ALL_CHANNEL')}
                                        defaultValue={false}
                                        isDisabled={!templateStore.hasAllChannels}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3} lg={3} xl={3}>
                                    <FormInputCheckbox
                                        name="isAllProjects"
                                        control={control}
                                        label={t('TEMPLATE_SAVE_ALL_PROJECTS')}
                                        defaultValue={false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sx={{ flexDirection: 'row', display: 'flex', gap: 2 }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ gap: 2, flexDirection: isXs ? 'column' : 'row', display: 'flex' }}>
                                    <Grid item xs={3} md={3} lg={3} xl={3}>
                                        <FormInputCheckbox
                                            name="isBlocked"
                                            control={control}
                                            label={t('TEMPLATE_BLOCK')}
                                            defaultValue={false}
                                        />
                                    </Grid>
                                    <Grid item xs={9} md={9} lg={9} xl={9} sx={{ gap: 2, justifyContent: 'end', display: 'flex' }}>
                                        {selectedInstance && selectedInstance!.isDefault! &&
                                            <RoundButton
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                title="Submit"
                                                data-testid="calendar-today"
                                                sx={{ width: 'auto' }}
                                                onClick={() => setIsCopy(true)}
                                            >
                                                {isLoading(templateStore.createTemplate) ? (
                                                    <CircularProgress size={25} />
                                                ) : (
                                                    <Typography fontSize="13px">{t('GENERAL_SAVE_AS_COPY')}</Typography>
                                                )}
                                            </RoundButton>
                                        }
                                        <RoundButton
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            title="Submit"
                                            data-testid="calendar-today"
                                            onClick={() => setIsCopy(false)}
                                        >
                                            {isLoading(templateStore.updateTemplate) ? (
                                                <CircularProgress size={25} />
                                            ) : (
                                                <Typography fontSize="13px">{t('GENERAL_SAVE')}</Typography>
                                            )}
                                        </RoundButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Grid >
            : <></>
    );
};

export default observer(TemplateDashboard);

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Chip, darken, lighten, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { StudyOverviewEventStatus } from "../../../../data/models/studyOverview";

export function getDueDateStatusProps(status: StudyOverviewEventStatus): { icon: JSX.Element; color: string } {
    switch (status) {
        case StudyOverviewEventStatus.DUE_SOON:
            return {
                icon: <AccessAlarmIcon sx={{ fontSize: "18px !important", ml: "10px !important", color: `${darken("#FFA500", 0.4)}!important` }} />,
                color: lighten("#FFA500", 0.7),
            };
        case StudyOverviewEventStatus.OVERDUE:
            return {
                icon: <AssignmentLateIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `  ${lighten("#FF0000", 0.3)}!important` }} />,
                color: lighten("#FF0000", 0.8),
            };
        case StudyOverviewEventStatus.PLANNED:
            return {
                icon: <EventNoteIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `${darken("#87CEEB", 0.3)} !important` }} />,
                color: lighten("#87CEEB", 0.4),
            };
        case StudyOverviewEventStatus.PROJECTED:
            return {
                icon: <TimelineIcon sx={{ fontSize: "18px !important ", ml: "10px !important", color: `${darken("#B19CD9", 0.4)} !important` }} />,
                color: lighten("#B19CD9", 0.7),
            };
        default:
            return {
                icon: <></>,
                color: "#ffffff",
            };
    }
}


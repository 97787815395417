import * as Yup from "yup";
import { combineDateAndTime, isAhvNumberValid } from "../../utils/utils";
import "yup-phone-lite";
import { contactAgencySchema } from "./contactAgency.schema";


export const combinedClientSchema = (isRegistered: boolean, update: boolean) => {
    const baseSchema = clientRegisterFormSchema(isRegistered, update);
    const contactSchema = contactAgencySchema();

    return baseSchema.concat(contactSchema);
};


export const clientRegisterFormSchema = (
    isRegistered: boolean,
    update: boolean
) => {
    if (update) return clientUpdateFormSchema(isRegistered);

    const schema = Yup.object().shape({ //
        firstName: Yup.string().required("Please enter user's first name").trim(),
        lastName: Yup.string().required("Please enter user's last name").trim(),
        birthdate: Yup.date()
            .typeError("Please enter user's birthdate")
            .required("Please enter user's birthdate")
            .max(new Date().getFullYear() + 1, "Invalid birthdate")
            .min(1900, "Invalid birthdate"),
        hasAccount: Yup.boolean().required(
            "Please select if the client has an account"
        ),
        password: Yup.string()
            .trim()
            .when("hasAccount", {
                is: true,
                then: (schema) => schema.required("Please enter a password"),
            }),
        confirmPassword: Yup.string()
            .trim()
            .when("hasAccount", {
                is: true,
                then: (schema) =>
                    schema
                        .oneOf([Yup.ref("password")], "Passwords must match")
                        .required("Confirm password is required"),
            }),
        preferredCommunicationChannel: Yup.string().required(
            "Please select prefered communication method"
        ),
        email: Yup.string()
            .trim()
            .when(["hasAccount", "preferredCommunicationChannel"], {
                is: (hasAccount: boolean, preferredCommunicationChannel: string) =>
                    hasAccount || preferredCommunicationChannel === "email",
                then: (schema) =>
                    schema
                        .email("Invalid email address")
                        .trim()
                        .required("Please enter an email"),
            }),
        emailConfirmation: Yup.string()
            .oneOf([Yup.ref("email")], "Emails must match")
            .when("preferredCommunicationChannel", {
                is: (preferredCommunicationChannel: string) =>
                    preferredCommunicationChannel === "email",
                then: (schema) => schema.required("Please confirm your email"),
            }),
        communicationLanguage: Yup.string().required("Please select a language"),
        phoneNumber: Yup.string()
            .trim()
            .phone("CH", "Please enter a valid phone number")
            .when("preferredCommunicationChannel", {
                is: (preferredCommunicationChannel: string) =>
                    preferredCommunicationChannel === "phone",
                then: (schema) => schema
                    .trim()
                    .required("Please enter a phone number"),
            })
            .nullable(),
        phoneNumberConfirmation: Yup.string()
            .oneOf([Yup.ref("phoneNumber")], "Phone numbers must match")
            .when("preferredCommunicationChannel", {
                is: (preferredCommunicationChannel: string) =>
                    preferredCommunicationChannel === "phone",
                then: (schema) => schema.required("Please confirm your phone number"),
            })
            .nullable(),
        roleIds: Yup.string().when("hasAccount", {
            is: true,
            then: (schema) =>
                schema
                    .trim()
                    .required("Please select at least one role"),
        }),
        ahvNumber: Yup.string()
            .matches(
                /^756\.(\d{4}\.){2}\d{2}$|^756\.____\.____\.__|^$/,
                "Invalid format. Should be 13 digits."
            )
            .test(
                "is-ahv-valid",
                "Invalid AHV number",
                function (ahvNumber): boolean {
                    if (
                        !ahvNumber ||
                        (ahvNumber &&
                            ahvNumber.replaceAll(".", "").replaceAll("_", "") === "756")
                    ) {
                        return true;
                    }
                    return isAhvNumberValid(ahvNumber!);
                }
            )
            .nullable(),
        zemisNumber: Yup.string()
            .test(
                "is-empty",
                "Invalid format. Should be 9 digits",
                function (zemisNumber): boolean {
                    if (zemisNumber) {
                        return zemisNumber.match(/^\d{8}\.\d$/) !== null;
                    }
                    return !zemisNumber;
                }
            )
            .nullable(),
        platformLanguageAlpha2: Yup.string()
            .trim()
            .when("hasAccount", {
                is: true,
                then: (schema) => schema.required("Please select a language"),
            }),
        userLanguages: Yup.array().when("hasAccount", {
            is: true,
            then: (schema) =>
                schema
                    .min(1, "Please select at least one language")
                    .required("Please select at least one language"),
        }),
    });

    return schema;
};

export const clientUpdateFormSchema = (isRegistered: boolean) => {
    if (isRegistered) {
        const schema = Yup.object().shape({
            firstName: Yup.string().required("Please enter user's first name").trim(),
            lastName: Yup.string().required("Please enter user's last name").trim(),
            communicationLanguage: Yup.string().required("Please select a language"),
            birthdate: Yup.date()
                .required("Please enter user's birthdate")
                .max(new Date().getFullYear() + 1, "Invalid birthdate")
                .min(1900, "Invalid birthdate"),
            preferredCommunicationChannel: Yup.string().required(
                "Please select prefered communication method"
            ),
            email: Yup.string()
                .trim()
                .when("preferredCommunicationChannel", {
                    is: (preferredCommunicationChannel: string) =>
                        preferredCommunicationChannel === "email",
                    then: (schema) =>
                        schema
                            .email("Invalid email address")
                            .trim()
                            .required("Please enter an email"),
                }),
            ahvNumber: Yup.string()
                .matches(
                    /^756\.(\d{4}\.){2}\d{2}$|^756\.____\.____\.__|^$/,
                    "Invalid format. Should be 13 digits."
                )
                .test(
                    "is-ahv-valid",
                    "Invalid AHV number",
                    function (ahvNumber): boolean {
                        if (
                            !ahvNumber ||
                            (ahvNumber &&
                                ahvNumber.replaceAll(".", "").replaceAll("_", "") === "756")
                        ) {
                            return true;
                        }
                        return isAhvNumberValid(ahvNumber!);
                    }
                )
                .nullable(),
            phoneNumber: Yup.string()
                .trim()
                .phone("CH", "Please enter a valid phone number")
                .when("preferredCommunicationChannel", {
                    is: (preferredCommunicationChannel: string) =>
                        preferredCommunicationChannel === "phone",
                    then: (schema) => schema
                        .trim()
                        .required("Please enter a phone number"),
                })
                .nullable(),
            zemisNumber: Yup.string()
                .test(
                    "is-empty",
                    "Invalid format. Should be 9 digits",
                    function (zemisNumber): boolean {
                        if (zemisNumber) {
                            return zemisNumber.match(/^\d{8}\.\d$/) !== null;
                        }
                        return !zemisNumber;
                    }
                )
                .nullable(),
        });

        return schema;
    }
    const schema = Yup.object().shape({
        firstName: Yup.string().required("Please enter user's first name").trim(),
        lastName: Yup.string().required("Please enter user's last name").trim(),
        communicationLanguage: Yup.string().required("Please select a language"),
        birthdate: Yup.date()
            .typeError("Please enter user's birthdate")
            .required("Please enter user's birthdate")
            .max(new Date().getFullYear() + 1, "Invalid birthdate")
            .min(1900, "Invalid birthdate"),
        hasAccount: Yup.boolean().required(
            "Please select if the client has an account"
        ),
        preferredCommunicationChannel: Yup.string().required(
            "Please select prefered communication method"
        ),
        password: Yup.string()
            .trim()
            .when("hasAccount", {
                is: true,
                then: (schema) => schema.required("Please enter a password"),
            }),
        confirmPassword: Yup.string()
            .trim()
            .when("hasAccount", {
                is: true,
                then: (schema) =>
                    schema
                        .oneOf([Yup.ref("password")], "Passwords must match")
                        .required("Confirm password is required"),
            }),
        email: Yup.string()
            .trim()
            .when(["hasAccount", "preferredCommunicationChannel"], {
                is: (hasAccount: boolean, preferredCommunicationChannel: string) =>
                    hasAccount || preferredCommunicationChannel === "email",
                then: (schema) =>
                    schema
                        .email("Invalid email address")
                        .trim()
                        .required("Please enter an email"),
            }),
        phoneNumber: Yup.string()
            .trim()
            .phone("CH", "Please enter a valid phone number")
            .when("preferredCommunicationChannel", {
                is: (preferredCommunicationChannel: string) =>
                    preferredCommunicationChannel === "phone",
                then: (schema) => schema
                    .trim()
                    .required("Please enter a phone number"),
            })
            .nullable(),
        platformLanguageAlpha2: Yup.string()
            .trim()
            .when("hasAccount", {
                is: true,
                then: (schema) => schema.required("Please select a language"),
            }),
        ahvNumber: Yup.string()
            .matches(
                /^756\.(\d{4}\.){2}\d{2}$|^756\.____\.____\.__|^$/,
                "Invalid format. Should be 13 digits."
            )
            .test(
                "is-ahv-valid",
                "Invalid AHV number",
                function (ahvNumber): boolean {
                    if (
                        !ahvNumber ||
                        (ahvNumber &&
                            ahvNumber.replaceAll(".", "").replaceAll("_", "") === "756")
                    ) {
                        return true;
                    }
                    return isAhvNumberValid(ahvNumber!);
                }
            )
            .nullable(),
        zemisNumber: Yup.string()
            .test(
                "is-empty",
                "Invalid format. Should be 9 digits",
                function (zemisNumber): boolean {
                    if (zemisNumber) {
                        return zemisNumber.match(/^\d{8}\.\d$/) !== null;
                    }
                    return !zemisNumber;
                }
            )
            .nullable(),
        userLanguages: Yup.array().when("hasAccount", {
            is: true,
            then: (schema) =>
                schema
                    .min(1, "Please select at least one language")
                    .required("Please select at least one language"),
        }),
    });

    return schema;
};

export const clientEventFormSchema = () => {
    const schema = Yup.object().shape({
        startDate: Yup.date().required("Start time is required"),
        endDate: Yup.date().test(
            "is-after-start",
            "Must be after start date",
            function (endDate): boolean {
                const startDate: Date = this.resolve(Yup.ref("startDate"));
                startDate.setHours(0, 0, 0, 0);
                endDate!.setHours(0, 0, 0, 0);

                return startDate <= endDate!;
            }
        ),
        isAllDay: Yup.boolean().required("Please select a value"),
        startTime: Yup.string()
            .trim()
            .when("isAllDay", {
                is: false,
                then: (schema) =>
                    schema
                        .typeError("Invalid start time")
                        .required("Start time is required"),
            }),
        endTime: Yup.string()
            .trim()
            .when("isAllDay", {
                is: false,
                then: (schema) =>
                    schema
                        .typeError("Invalid end time")
                        .required("End time is required")
                        .test(
                            "is-after-start",
                            "Must be after start time",
                            function (endTime): boolean {
                                const startTime: Date = this.resolve(Yup.ref("startTime"));
                                const startDate: Date = this.resolve(Yup.ref("startDate"));
                                const startTimeDate = combineDateAndTime(
                                    startDate,
                                    new Date(startTime)
                                );

                                const endDate: Date = this.resolve(Yup.ref("endDate"));
                                const endTimeDate = combineDateAndTime(
                                    endDate,
                                    new Date(endTime)
                                );

                                return endTimeDate > startTimeDate;
                            }
                        ),
            }),
        recurrence: Yup.object().shape({
            byDays: Yup.array().test('is-weekly', 'Select at least one day', function (values): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 2) {
                    return values ? values.length > 0 : false;
                }

                return true;
            }),
            endDate: Yup.date().test('is-after-start', 'Must be after start date', function (endDate): boolean {
                const parent: any = this.options;
                const startDate = parent.from[1].value.startDate;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 9) {
                    return true;
                }

                return startDate <= endDate!;
            }),
            byMonthDay: Yup.number().test('is-monthly', 'Select the recurrence date', function (value): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 1) {
                    return value ? value >= 1 && value <= 30 : false;
                }

                return true;
            }),
            interval: Yup.number().test('is-recurrent', 'Select an interval', function (value): boolean {
                const parent: any = this.options;
                const frequency = parent.from[1].value.frequency;

                if (frequency === 9) {
                    return true;
                } else if ([1, 2, 3].includes(frequency)) {
                    return value ? value >= 1 && value <= 5 : false;
                }

                return true;
            })
        })
    });

    return schema;
};

import { Box, Divider, IconButton, Typography } from "@mui/material";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";

import { settingsOptions } from "./options";
import SettingsHeader from "./SettingsHeader";
import { SideBar, SideBarItem } from "../../_styles/ListItem";
import { useStore } from "../../stores/store";
import FlexContainer, { Option } from "../../_styles/layout/Container";
import ConfirmDeleteDialog from "../../components/ConfirmDialog";
import LoadingComponent from "../../components/LoadingComponent";
import "./styles.css";
import { useTranslation } from "react-i18next";

function SettingsDashboard() {
    const { t } = useTranslation();
    const {
        sidebarStore: { closeSidebar },
        tenantStore: { getTenants },
    } = useStore();
    const { option } = useParams();
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(
        settingsOptions.find((opt) => opt.id === option)
    );
    const navigate = useNavigate();

    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        getTenants();
        const pathOption = settingsOptions.find((opt) => opt.id === option);
        if (pathOption) {
            setSelectedOption(pathOption);
        } else {
            navigate("/settings/tenants");
        }

        return () => {
            closeSidebar();
        };
    }, [getTenants, closeSidebar, option, navigate]);

    useLayoutEffect(() => {
        const appContainer = document.querySelector(
            ".app-container"
        ) as HTMLElement;

        const oldValue = appContainer.style.display;
        const oldOY = appContainer.style.overflowY;
        const oldOX = appContainer.style.overflowX;
        appContainer.style.cssText =
            "display: flex !important; overflow-y: auto !important; overflow-x: hidden !important;";

        return () => {
            appContainer.style.display = oldValue;
            appContainer.style.overflowY = oldOY;
            appContainer.style.overflowX = oldOX;
        };
    }, []);

    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        navigate(`/settings/${option.id}`);
        closeSidebar();
    };

    if (!selectedOption) return <LoadingComponent />

    return (
        <Box className="settings-wrapper">
            <ConfirmDeleteDialog />

            <Box style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
                <Box className={`options-container ${toggle ? "options-hidden" : ""}`}
                >
                    <Box className="highlight"></Box>
                    <Box className="settings-toggle-container">
                        <IconButton
                            className="settings-toggle-button"
                            disableRipple
                            onClick={() => setToggle(!toggle)}
                            sx={{
                                display: toggle ? "-webkit-inline-flex" : "none",
                            }}
                        >
                            {toggle ? (
                                <ChevronRightRoundedIcon sx={{ fontSize: "18px" }} />
                            ) : (
                                <ChevronLeftRoundedIcon sx={{ fontSize: "18px" }} />
                            )}
                        </IconButton>
                    </Box>
                    <Box
                        className="sticky-container"
                        sx={{ display: toggle ? "none" : "block" }}
                    >
                        <Typography
                            className="settings-header-title"
                            sx={{ fontWeight: "600", fontSize: "25px", ml: 1, mb: 4 }}
                        >
                            {t("SETTINGS_NAME")}
                        </Typography>
                        <SideBar
                            className="options"
                            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, mt: 2 }}
                        >
                            {settingsOptions.map((option) => (
                                <SideBarItem
                                    className="settings-option"
                                    key={option.id}
                                    onClick={() => handleOptionClick(option)}
                                    selected={selectedOption?.id === option.id}
                                    sx={{
                                        borderBottom: "none !important",
                                        bgcolor:
                                            selectedOption?.id === option.id
                                                ? "#EBECF0 !important"
                                                : "transparent",
                                    }}
                                >
                                    {selectedOption?.id === option.id
                                        ? option.selectedIcon
                                        : option.icon}
                                    <Typography
                                        paddingLeft={2}
                                        fontSize="14px"
                                        sx={{
                                            color:
                                                selectedOption?.id === option.id ? "#004e7c" : "#000",
                                        }}
                                    >
                                        {t(`SETTINGS_${option.name}_NAME`)}
                                    </Typography>
                                </SideBarItem>
                            ))}
                        </SideBar>
                    </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
                {selectedOption && (
                    <Box className="settings-dashboard-container">
                        <SettingsHeader
                            componentForm={selectedOption!.form!}
                            label={selectedOption!.formLabel!}
                            handleChange={handleOptionClick}
                            path={t(`SETTINGS_${selectedOption!.name}_NAME`)}
                            selected={selectedOption!}
                        />

                        <FlexContainer
                            selectedOption={selectedOption}
                            className="settings-flex-container"
                        >
                            {selectedOption.component}
                        </FlexContainer>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default observer(SettingsDashboard);

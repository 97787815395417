import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../components/form/SearchBar';
import StudyOverviewFilter from './StudyOverviewFilter';

type Props = {
    onSearch: (searchValue: string) => void;
    onGroupChange: (groups: string[]) => void;
};

const StudyOverviewToolbar: React.FC<Props> = ({ onSearch, onGroupChange }) => {
    const { t } = useTranslation()
    return (
        <Grid container>
            <Grid item xs={12} sm={4} lg={3} marginBottom={3} >
                <SearchBar onSearch={onSearch} isRounded={false} placeholder={t("SEARCH_BY_CLIENT_OR_RESPONSIBLE_PEOPLE")} />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
                <StudyOverviewFilter onGroupChange={onGroupChange} />
            </Grid>
        </ Grid>
    )
}

export default StudyOverviewToolbar
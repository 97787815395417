import { useTranslation } from "react-i18next";
import FormInputChipArea from "../../../components/form/FormInputChipArea";
import { AttendeeDto } from "../../../data/models/user";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";

interface AttendeeInputProps {
    control: any;
    name: string;
    label: string;
    defaultValues?: string[];
}

function AttendeeInput({ control, name, label, defaultValues }: AttendeeInputProps) {
    const { t } = useTranslation();
    const { bookingStore: { eventAttendeeList } } = useStore();
    function isEmailValid(email: string) {
        const regex = /^[A-zÀ-ž0-9][A-z0-9À-ž._%+-]+@[A-z0-9À-ž._%+-]+\.[a-z]{2,4}$/;
        return regex.test(email);
    }

    const externalAttendees = defaultValues?.filter(email => !eventAttendeeList.map(user => user.email).includes(email)).map(email => {
        const attendee: AttendeeDto = {
            name: email,
            email: email
        };
        return attendee;
    });

    const platformAttendees = eventAttendeeList.filter(user => defaultValues?.includes(user.email)).map(user => {
        const attendee: AttendeeDto = {
            name: user.firstName + " " + user.lastName,
            email: user.email
        };
        return attendee;
    });

    return (
        <FormInputChipArea
            name={name}
            control={control}
            label={label}
            defaultValues={externalAttendees ? [...platformAttendees, ...externalAttendees] : platformAttendees}
            validation={isEmailValid}
            validationMessage={t("EVENT_ATTENDEE_ERR")}
            helperText=""
            options={eventAttendeeList.map(user => {
                const attendee: AttendeeDto = {
                    name: user.firstName + " " + user.lastName,
                    email: user.email
                };

                return attendee;
            })}
        />
    );
}

export default observer(AttendeeInput);
import { Box } from "@mui/material";

import "../styles.css";
import ClientInfo from "./ClientInfo";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";
import { Pagination } from "../../../data/models/pagination";

function ClientDetailsDashboard() {
    const {
        clientStore: { getClient, getClientTasks, getTimeline, clearTimeline },
        institutionStore: { selectedUserInstitution }
    } = useStore();
    const { id } = useParams();
    const [pagination, setPagination] = useState<Pagination>({ page: 1, pageSize: 10 });

    useEffect(() => {
        const fetchData = async () => {
            if (id && selectedUserInstitution) {
                await getClient(id, selectedUserInstitution.institutionId);
                await getClientTasks(id);
                await getTimeline(id, pagination);
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, pagination, selectedUserInstitution]);

    useEffect(() => {
        return () => {
            clearTimeline()
        }
    }, [clearTimeline])

    const handlePageParams = () => {
        setPagination((prevPagination) => ({ ...prevPagination, page: prevPagination.page + 1 }))
    }

    return (
        <Box className="details-dashboard-container">
            <ClientInfo onPageParamsChange={handlePageParams} />
        </Box>
    );
}

export default observer(ClientDetailsDashboard);
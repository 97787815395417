import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import Business from '@mui/icons-material/BusinessOutlined';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockIcon from '@mui/icons-material/LockOutlined';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenterOutlined';
import DoorSlidingRoundedIcon from '@mui/icons-material/DoorSlidingRounded';
import DoorSlidingIcon from '@mui/icons-material/DoorSlidingOutlined';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import GroupIcon from '@mui/icons-material/GroupOutlined';

import { Option } from '../../_styles/layout/Container';
import TenantTable from './Tenant/TenantTable';
import TenantRegisterForm from './Tenant/TenantRegisterForm';
import RoleTable from './Role/RoleTable';
import RoleForm from './Role/RoleForm';
import InstitutionRegisterForm from './Institution/InstitutionRegisterForm';
import RoomForm from './Room/RoomForm';
import UserTable from './User/UserTable';
import UserRegisterForm from './User/UserRegisterForm';
import InstitutionTable from './Institution/InstitutionTable';
import RoomTable from './Room/RoomTable';

const iconStyle = {
    color: '#a9a9a9',
};

export const settingsOptions: Option[] = [
    {
        id: 'tenants',
        name: 'TENANTS',
        component: <TenantTable />,
        form: <TenantRegisterForm />,
        formLabel: 'Add tenant',
        buttonLabel: 'CREATE',
        icon: <Business sx={iconStyle} fontSize='small' />,
        selectedIcon: <BusinessRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
    {
        id: 'institutions',
        name: 'INSTITUTIONS',
        component: <InstitutionTable />,
        form: <InstitutionRegisterForm />,
        formLabel: 'Add institution',
        buttonLabel: 'CREATE',
        icon: <BusinessCenterIcon sx={iconStyle} fontSize='small' />,
        selectedIcon: <BusinessCenterRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
    {
        id: 'users',
        name: 'USERS',
        component: <UserTable />,
        form: <UserRegisterForm />,
        formLabel: 'Add or assign user',
        buttonLabel: 'CREATE',
        icon: <GroupIcon sx={iconStyle} fontSize='small' />,
        selectedIcon: <PeopleAltRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
    {
        id: 'roles',
        name: 'ROLES',
        component: <RoleTable />,
        form: <RoleForm />,
        formLabel: 'Add role',
        buttonLabel: 'CREATE',
        icon: <LockIcon sx={iconStyle} fontSize='small' />,
        selectedIcon: <LockRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
    // {
    //     id: 'calendar',
    //     name: 'Calendar',
    //     component: <CalendarForm />,
    //     icon: <CalendarTodayOutlinedIcon sx={iconStyle} fontSize='small' />,
    //     selectedIcon: <CalendarTodayRoundedIcon sx={{ color: "#0096c7" }} fontSize='small' />
    // },
    {
        id: 'rooms',
        name: 'ROOMS',
        component: <RoomTable />,
        form: <RoomForm />,
        formLabel: 'Add room',
        buttonLabel: 'CREATE',
        icon: <DoorSlidingIcon sx={iconStyle} fontSize='small' />,
        selectedIcon: <DoorSlidingRoundedIcon sx={{ color: "#004e7c" }} fontSize='small' />
    },
];

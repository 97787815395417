import { Navigate, Outlet, useLocation } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { useStore } from "../stores/store";
import { hasModulePermission } from "../utils/permissionEvaluator";

interface AuthProps {
    permissions?: string[];
    redirect?: string;
    module?: string;
    role?: string | string[];
    requireAll?: boolean;
}

function RequireAuth({ permissions, redirect, module, role, requireAll = true }: AuthProps) {
    const {
        authStore: { isLoggedIn, user },
        institutionStore: { selectedUserInstitution },
        tenantStore: { selectedTenant },
    } = useStore();
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to={redirect || '/'} state={{ from: location }} />
    }

    if ((permissions && !module) || (module && !permissions)) {
        return <Navigate to={redirect || "/"} state={{ from: location }} />
    }

    if (permissions) {
        // TODO: Check if this is the best way to handle this
        if (!selectedUserInstitution) return null;

        const hasRole = selectedUserInstitution.roles.find(r =>
            (r.toUpperCase() === "ADMIN" && !module?.includes("DOWNLOAD")) ||
            user?.isSuperAdmin ||
            (role && (Array.isArray(role)
                ? role.some(rItem => r.toUpperCase().includes(rItem.toUpperCase()))
                : r.toUpperCase().includes(role.toUpperCase())))
        );
        const hasRequiredPermissions = hasModulePermission(permissions, module!, selectedUserInstitution!, requireAll);
        if (!hasRequiredPermissions && !hasRole) {
            return <Navigate to={redirect || "/"} state={{ from: location }} />;
        }
    }

    if (role) {
        if (!selectedUserInstitution) return null;

        const hasRole = selectedUserInstitution.roles.find(r =>
            (r.toUpperCase() === "ADMIN" && !module?.includes("DOWNLOAD"))
            || user?.isSuperAdmin ||
            (Array.isArray(role)
                ? role.some(rItem => r.toUpperCase().includes(rItem.toUpperCase()))
                : r.toUpperCase().includes(role.toUpperCase()))
        );
        if (!hasRole) {
            return <Navigate to={redirect || "/profile/account"} state={{ from: location }} />;
        }
    }

    if (location.pathname.startsWith("/settings") && selectedTenant?.isAdmin !== true) {
        return <Navigate to={redirect || "/profile/account"} state={{ from: location }} />;
    }

    return <Outlet />
}

export default observer(RequireAuth)
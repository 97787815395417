import { Box, Container, Typography, Grid } from "@mui/material";

import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { observer } from "mobx-react-lite";

import { getDecodedToken } from "../../utils/jwtDecoder";
import { Totp } from "../../data/models/user";
import { useStore } from "../../stores/store";
import { adminVerifySchema } from "../../_validators/schemas/adminVerify.schema";
import { FormInputText } from "../../components/form/FormInputText";
import { RoundButton } from "../../_styles/StyledButtons";
import { useTranslation } from "react-i18next";

function TwoFactorConfirmation() {
    const { t } = useTranslation();
    const {
        totpStore: { adminConfirmation, generatePsk },
        modalStore: { closeModal },
        authStore: { isLoggedIn, getCurrentUser },
    } = useStore();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [rawToken, setRawToken] = useState<string | null>(params.get("token"));
    const [token, setToken] = useState<any>(getDecodedToken(rawToken));
    const [qrCode, setQrcode] = useState("");
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(adminVerifySchema()),
    });
    const { state } = useLocation();

    const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
        const twoFactorObject: Totp = {
            totp: data.verification,
            email: token.email,
        };

        await adminConfirmation(twoFactorObject, rawToken);
        toast.success(t("TOAST_USER_VERIFY"));
        if (!isLoggedIn) {
            navigate("/login", { state: { redirect: state.redirect } });
            window.history.replaceState(null, "", "/");
        } else {
            await getCurrentUser();
            closeModal();
        }
    };

    useEffect(() => {
        const fetchToken = async () => {
            if (!token) {
                const response = await generatePsk();
                setRawToken(response.token);
                setToken(getDecodedToken(response.token));
            }
        };

        fetchToken().then(() => {
            if (token) {
                // TODO: Fix the token expiration date
                const expirationDate = new Date(token.exp);
                const currentDate = new Date();
                // TODO: Fix the condition to check for the expiration date correctly
                if (expirationDate > currentDate) {
                    if (isLoggedIn) {
                        closeModal();
                    } else {
                        navigate("/");
                    }
                }

                const env = process.env.REACT_APP_ENVIRONMENT ?? "";
                let label = "Spirit " + env;
                let qrcodeTotp = `otpauth://totp/${encodeURIComponent(
                    label
                )}:${encodeURIComponent(token.email)}?secret=${encodeURIComponent(
                    token.psk.replaceAll("=", "")
                )}&issuer=${encodeURIComponent(label)}&label=${encodeURIComponent(
                    label
                )}`;
                setQrcode(qrcodeTotp);
            }
        });
    }, [closeModal, generatePsk, isLoggedIn, navigate, token]);

    return (
        <Container
            component="main"
            maxWidth="xs"
            sx={{ marginBottom: 3, marginTop: 10, textAlign: "center" }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Grid container spacing={3} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12}>
                        <Typography>
                            {t("2FA_SETUP_MSG")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <QRCode value={qrCode} level="M" width={256} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormInputText
                            name="verification"
                            label={`${t("2FA_INPUT_CODE")} *`}
                            control={control}
                        />
                    </Grid>
                </Grid>
                <RoundButton
                    variant="contained"
                    sx={{ mt: 3 }}
                    type="submit"
                    title="Verify"
                    data-testid="verify-button"
                >
                    <Typography fontSize="13px">{t("2FA_VERIFY")}</Typography>
                </RoundButton>
            </Box>
        </Container>
    );
}

export default observer(TwoFactorConfirmation);

import { useEffect, useMemo, useState } from "react";
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Stack,
    Chip,
    Box,
    FormHelperText,
    ListSubheader,
    TextField,
    InputAdornment,
    CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { FormInputProps } from "./FormInputProps";
import { Controller } from "react-hook-form";

interface ChipSelectProps<T> extends FormInputProps {
    options: T[];
    labelKey: keyof T;
    valueKey: keyof T;
    defaultValues?: string[];
    isLimited?: boolean;
    search?: boolean;
    isDisabled?: boolean;
    loading?: boolean;
}

function FormInputChipSelect<T extends { [key: string]: any }>({
    name,
    label,
    control,
    options,
    valueKey,
    labelKey,
    defaultValues = [],
    isLimited = false,
    search = false,
    helperText = " ",
    isDisabled = false,
    loading = false
}: ChipSelectProps<T>) {
    const [selectedNames, setSelectedNames] = useState<string[]>([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        setSelectedNames(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const displayedOptions = useMemo(
        () =>
            options.filter((option) =>
                option[labelKey]?.toLowerCase().includes(searchText.trim().toLowerCase())
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchText, options]
    );

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={selectedNames}
            render={({ fieldState: { error }, field: { onChange } }) => (
                <Box>
                    <InputLabel sx={{ fontSize: "14px" }}>{label}</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            multiple
                            error={!!error}
                            value={selectedNames}
                            onClose={() => setSearchText("")}
                            onChange={(e: any) => {
                                if (isLimited && e.target.value.length === 0) return;
                                setSelectedNames(e.target.value);
                                onChange(e);
                            }}
                            data-testid={name}
                            input={<OutlinedInput label="Multiple Select" />}
                            disabled={isDisabled}
                            SelectDisplayProps={{
                                style: {
                                    padding: `${selectedNames.length > 0 ? '4.85px' : '8px'} 10px`,
                                    backgroundColor: "white",
                                    fontSize: "13px",
                                },
                            }}
                            MenuProps={{ sx: { width: 100, height: 300 }, autoFocus: false }}
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                lineHeight: "inherit",
                            }}
                            renderValue={(selected) => (
                                <Stack gap={1} direction="row" flexWrap="wrap">
                                    {options
                                        .filter((option) => selected.includes(option[valueKey]))
                                        .map((option) => (
                                            <Chip
                                                key={option[valueKey]}
                                                label={option[labelKey]}
                                                data-testid={`${name}-chip-${option[valueKey]}`}
                                                sx={{ height: "25px", fontSize: "12px" }}
                                                onDelete={() => {
                                                    const newSelectedNames = selectedNames.filter(
                                                        (name) => name !== option[valueKey]
                                                    );
                                                    setSelectedNames(newSelectedNames);
                                                    onChange({
                                                        target: {
                                                            value: newSelectedNames,
                                                            name: name,
                                                        },
                                                    });
                                                }}
                                                deleteIcon={
                                                    isLimited ? (
                                                        selected.length !== 1 ? (
                                                            <CancelIcon
                                                                onMouseDown={(event) => event.stopPropagation()}
                                                            />
                                                        ) : (
                                                            <NotInterestedIcon />
                                                        )
                                                    ) : (
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    )
                                                }
                                            />
                                        ))}
                                </Stack>
                            )}
                        >
                            {search && !loading && (
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Type to search..."
                                        fullWidth
                                        sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                        inputProps={{
                                            style: {
                                                fontSize: "13px",
                                                padding: "8px 10px",
                                            },
                                            'data-testid': `${name}-search`,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                            )}
                            {loading ?
                                <MenuItem disabled sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CircularProgress size={18} />
                                </MenuItem>
                                : search
                                    ? displayedOptions.map((option: any) => (
                                        <MenuItem
                                            key={option[valueKey]}
                                            value={option[valueKey]}
                                            data-testid={`${name}-option-${option[valueKey]}`}
                                            sx={{ justifyContent: "space-between", fontSize: "13px" }}
                                        >
                                            {option[labelKey]}
                                            {selectedNames.includes(option[valueKey]) ? (
                                                <CheckIcon color="info" />
                                            ) : null}
                                        </MenuItem>
                                    ))
                                    : options.map((option: any) => (
                                        <MenuItem
                                            key={option[valueKey]}
                                            value={option[valueKey]}
                                            data-testid={`${name}-option-${option[valueKey]}`}
                                            sx={{ justifyContent: "space-between", fontSize: "13px" }}
                                        >
                                            {option[labelKey]}
                                            {selectedNames.includes(option[valueKey]) ? (
                                                <CheckIcon color="info" />
                                            ) : null}
                                        </MenuItem>
                                    ))}
                        </Select>
                        <FormHelperText error={!!error}>
                            {error?.message || helperText}
                        </FormHelperText>
                    </FormControl>
                </Box>
            )}
        />
    );
}

export default FormInputChipSelect;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../../../stores/store";

export const useStudyOverview = () => {
    const { studyOverviewStore, institutionStore, loadingStore } = useStore();
    const { getStudyOverview, studyOverviewList } = studyOverviewStore;
    const { selectedUserInstitution } = institutionStore;
    const { isLoading } = loadingStore;

    const paginationString = sessionStorage.getItem("study-overview-pagination");
    const paginationParams = paginationString ? JSON.parse(paginationString) : null;
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [pagination, setPagination] = useState({
        page: paginationParams?.page ?? 1,
        pageSize: paginationParams?.pageSize ?? 10,
    });
    const [searchQuery, setSearchQuery] = useState("");

    const maxPage = useRef(pagination.page);
    const isTableLoading = isLoading(getStudyOverview);

    const totalCount = useMemo(() => {
        return paginationParams?.TotalCount ?? studyOverviewList.length;
    }, [paginationParams?.TotalCount, studyOverviewList.length]);

    const fetchStudyOverviewData = useCallback(async (filterChange: boolean = false) => {
        if (!selectedUserInstitution?.institutionId) return;

        const params = {
            ...pagination,
            ...(searchQuery ? { searchTerm: searchQuery } : {}),
            ...(selectedGroups.length > 0 ? { groupFilter: selectedGroups } : {}),
        };
        await getStudyOverview(
            selectedUserInstitution.institutionId,
            params,
            filterChange
        );
    }, [getStudyOverview, pagination, searchQuery, selectedUserInstitution?.institutionId, selectedGroups]);

    const fetchStudyOverviewDataRef = useRef(fetchStudyOverviewData);

    useEffect(() => {
        fetchStudyOverviewDataRef.current = fetchStudyOverviewData;
    }, [fetchStudyOverviewData]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            page: 1,
        }));
        fetchStudyOverviewDataRef.current(true);
    }, [selectedUserInstitution, searchQuery, selectedGroups]);

    useEffect(() => {
        fetchStudyOverviewDataRef.current();
    }, [pagination.page, pagination.pageSize, selectedUserInstitution]);

    useEffect(() => {
        return () => {
            studyOverviewStore.reset();
        };
    }, [studyOverviewStore]);

    const handleGroupChange = (groups: string[]) => {
        setSelectedGroups(groups);
        setPagination((prev) => ({ ...prev, page: 1 }));
    };

    const handlePageChange = (newPage: number, newPageSize: number) => {
        const updatedPagination = { page: newPage + 1, pageSize: newPageSize };
        setPagination(updatedPagination);
        maxPage.current = newPage + 1;

    };
    const handleSearchChange = (search: string) => {
        setSearchQuery(search);
        setPagination({ page: 1, pageSize: pagination.pageSize });
    };

    return {
        studyOverviewList,
        pagination,
        handlePageChange,
        handleSearchChange,
        handleGroupChange,
        isTableLoading,
        rowCount: totalCount,
        fetchStudyOverviewData
    };
};

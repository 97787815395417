import { SessionStatus } from "./session";

export interface StudyOverviewDTO {
    clientId: string;
    clientName: string;
    responsiblePeople: string[];
    timeline: string;
    currentEvent: string;
    group: string;
    upcomingEvent: string;
    date: Date;
    dueDate: Date;
    overDue: Date;
    sessionId: string;
    upcomingEventStatus: StudyOverviewEventStatus;
    sessionStatus: SessionStatus;
    eventStatus: EventStatus;
}

export enum StudyOverviewEventStatus {
    PLANNED = "PLANNED",
    PROJECTED = "PROJECTED",
    DUE_SOON = "DUE_SOON",
    OVERDUE = "OVER_DUE"
}

export enum EventStatus {
    OVERDUE = "OVERDUE",
    COMPLETED = "COMPLETED",
    IN_PROGRESS = "IN_PROGRESS",
    CLIENT_NO_SHOW_UP = "CLIENT_NO_SHOW_UP"
}
import { AppRoles, PermissionDto } from "../data/models/role";

export const hasModuleAccess = (module: string, permissions: PermissionDto, isSuperAdmin: boolean | undefined = false) => {
    if (permissions) {
        return permissions.permissions.some(permission => {
            if (permission.module === module) {
                const hasAdminRole = permissions.roles.some(role => role.toUpperCase().includes("ADMIN"));

                if (isSuperAdmin) {
                    return permission.isActive;
                }

                if (hasAdminRole && module.includes("DOWNLOAD")) {
                    return false;
                }

                return permission.isActive;
            }
            return false;
        });
    }
    return false;
};

export const getModulePermissions = (module: string, userPermissions: PermissionDto): string[] => {
    if (!userPermissions) {
        return [];
    }

    const modulePermissions = userPermissions.permissions
        .find(permission => permission.module === module && permission.isActive);

    if (!modulePermissions) {
        return [];
    }
    const accessPermissions = modulePermissions.access;

    return accessPermissions
}

export const hasModulePermission = (
    permissions: string[],
    module: string,
    userPermissions: PermissionDto,
    requireAll: boolean = true
) => {
    if (!userPermissions) {
        return false;
    }

    const modulePermissions = userPermissions.permissions
        .filter(permission => permission.module === module && permission.isActive);

    if (modulePermissions.length === 0) {
        return false;
    }

    const accessPermissions = modulePermissions[0].access;
    const hasAllPermissions = permissions.every(permission => accessPermissions.includes(permission));
    const hasAnyPermission = permissions.some(permission => accessPermissions.includes(permission));

    return requireAll ? hasAllPermissions : hasAnyPermission;
};

/**
 * @deprecated Use hasRolesV2 with AppRoles enum instead.
 */
export const hasRoles = (roles: string[], userRoles: string[], requireAll: boolean = false) => {
    const lowercasedRoles = roles.map(role => role.toLowerCase());
    const lowercasedUserRoles = userRoles.map(userRole => userRole.toLowerCase());

    if (requireAll) {
        return lowercasedRoles.every(role => lowercasedUserRoles.includes(role));
    } else {
        return lowercasedRoles.some(role => lowercasedUserRoles.includes(role));
    }
}

export const hasRolesv2 = (institutionRoles: AppRoles[], userRoles: AppRoles[], requireAll: boolean = false) => {
    const lowercasedRoles = institutionRoles.map(role => role.toLowerCase());
    const lowercasedUserRoles = userRoles.map(userRole => userRole.toLowerCase());

    if (requireAll) {
        return lowercasedRoles.every(role => lowercasedUserRoles.includes(role));
    } else {
        return lowercasedRoles.some(role => lowercasedUserRoles.includes(role));
    }
}



export const hasOtherRoles = (roles: string[], userRoles: string[]) => {
    const lowercasedRoles = roles.map(role => role.toLowerCase());
    const lowercasedUserRoles = userRoles.map(userRole => userRole.toLowerCase());

    return lowercasedUserRoles.some(userRole => !lowercasedRoles.includes(userRole));
}
import { Box } from "@mui/system";

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import DashboardHeader from "./DashboardHeader";
import ClientList from "./ClientList";
import { useStore } from "../../../stores/store";
import { Pagination } from "../../../data/models/pagination";
import { SortType } from "../../../components/GenericTable";
import "../styles.css";

function ClientDashboard() {
    const {
        clientStore: { getClients, getClientRoles },
        tenantStore: { selectedTenant },
        institutionStore: { selectedUserInstitution },
    } = useStore();
    const [roleId, setRoleId] = useState<number>(-1);
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        pageSize: 10,
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState<{ key: string, type: SortType }>({ key: "", type: "desc" });

    useEffect(() => {
        const fetchData = async () => {
            if (selectedUserInstitution) {
                await getClients(selectedUserInstitution.institutionId, {
                    roleId: roleId !== -1 ? roleId : undefined,
                    paging: pagination,
                    search: searchQuery,
                    sortField: sortColumn.key,
                    sortOrder: sortColumn.type,
                }, true);
            }

            if (selectedTenant && !selectedUserInstitution?.roles.some(r => r.toUpperCase() === "CLIENT")) {
                await getClientRoles(selectedTenant.id);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserInstitution, selectedTenant]);

    useEffect(() => {
        setPagination({ page: 1, pageSize: 10 });
    }, [selectedUserInstitution, selectedTenant]);

    useEffect(() => {
        setPagination({ page: 1, pageSize: 10 });
        handleChange(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId]);

    useEffect(() => {
        handleChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    useEffect(() => {
        handleChange(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, sortColumn])

    const handleFilterState = async (newRoleId: number) => {
        setRoleId(newRoleId);
    };

    const onSort = async (key: string, type: SortType) => {
        setSortColumn({ key, type });
    }

    const onPageChange = async (page: number, pageSize: number) => {
        setPagination({ page: page + 1, pageSize });
    }

    const onSearch = async (searchValue: string) => {
        setPagination({ page: 1, pageSize: 10 });
        setSearchQuery(searchValue);
    };

    const handleChange = async (filterChange: boolean = false) => {
        if (selectedUserInstitution) {
            await getClients(
                selectedUserInstitution.institutionId,
                {
                    roleId: roleId !== -1 ? roleId : undefined,
                    paging: pagination,
                    search: searchQuery,
                    sortField: sortColumn.key,
                    sortOrder: sortColumn.type,
                },
                filterChange);
        }
    }

    return (
        <Box className="client-dashboard">
            {!selectedUserInstitution?.roles!.some(r => r.toUpperCase() === "CLIENT") ?
                <>
                    <DashboardHeader onSearch={onSearch} />
                </> : <Box sx={{ padding: 5 }}></Box>
            }
            <ClientList onSort={onSort} onPageChange={onPageChange} onRoleChange={handleFilterState} />
        </Box>
    );
}

export default observer(ClientDashboard);

import { Box, Typography, alpha, lighten } from "@mui/material"
import LoopIcon from '@mui/icons-material/Loop';
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

import { observer } from "mobx-react-lite"
import dayjs from "dayjs";
import { useStore } from "../../../stores/store";
import { generateColor } from "../../../utils/colorGenerator";
import { useLayoutEffect, useRef, useState } from "react";

import { Views } from "react-big-calendar";
import { EventIcon } from "./EventTypeIcons";

const eventStyle = {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    width: '100%',
    height: '100%',
}

function Event({ event }: any) {
    const { bookingStore: { viewType } } = useStore();
    const startTime = dayjs(event.start).format('HH:mm A');
    const [iconHeight, setIconHeight] = useState(0);
    const boxRef = useRef<HTMLDivElement>(null);
    const bgColor = generateColor(event.viewId);
    const isAccepted = event.eventStatus === "ACCEPTED" || event.eventStatus === null;

    useLayoutEffect(() => {
        if (boxRef.current) {
            const parentHeight = boxRef.current.offsetHeight;
            setIconHeight(parentHeight)
        }
    }, [event.start, event.end, boxRef]);

    //Events with eventId empty are considered events of another tenant
    return (
        <Box sx={{ ...eventStyle, justifyContent: 'flex-start !important' }} ref={boxRef}>
            <EventIcon type={event.eventType} bgColor={bgColor} style={{ height: iconHeight, marginRight: '1px' }} />
            <Box sx={{
                ...eventStyle,
                width: viewType === Views.MONTH ? '95%' : viewType === Views.WEEK && !event.isAllDay ? '75%' : '100%',
                backgroundColor: alpha(lighten(bgColor, 0.7), isAccepted ? 1 : 0.4),
                pr: '3px',
                border: isAccepted ? 'none' : `1px dashed ${bgColor}`,
                borderLeft: 'none',
                borderRadius: '0 3px 3px 0',
                position: 'relative',
                '&:hover': {
                    backgroundColor: alpha(lighten(bgColor, 0.6), isAccepted ? 1 : 0.4),
                }
            }}>
                <Box sx={{
                    ...eventStyle,
                    justifyContent: 'flex-start !important',
                    width: '90%',
                }}>
                    {viewType === 'month' && event.id !== '' &&
                        <Typography component="h6" variant="body1" fontSize="12px">
                            {startTime}
                        </Typography>}
                    <Typography
                        component="h6"
                        variant="body1"
                        fontSize={viewType !== Views.DAY && event.id === '' ? '12px' : '14px'}
                        paddingLeft="5px"
                        fontWeight={500}
                        width={viewType === Views.MONTH ? (event.id !== '' ? "65px" : '100%') : "90%"}
                        maxHeight="90%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        alignSelf={viewType === Views.MONTH ? "center" : 'flex-start'}
                        sx={{ wordWrap: 'normal' }}
                    >{event.id !== ''
                        ? event.title
                        : `${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}`}</Typography>
                </Box>
                <Box
                    width="20% !imporant"
                    sx={{
                        position: 'absolute',
                        right: '1px',
                        bottom: viewType === Views.MONTH ? 'auto' : '1px',
                    }}>
                    {event.recurrence !== 'no-repeat' && event.id !== '' && <LoopIcon sx={{
                        fontSize: '16px !important',
                        height: '100%',
                    }} />}
                    {((event.recurrence !== 'no-repeat' && event.id === '') || (event.id === '')) && <CorporateFareOutlinedIcon sx={{
                        fontSize: '16px !important',
                        height: '100%',
                    }} />}
                </Box>
            </Box>
        </Box >
    )
}

export default observer(Event)
import {
    CircularProgress,
    Grid,
    Box,
    Typography,
    Divider,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { FormInputText } from "../../../components/form/FormInputText";
import { useStore } from "../../../stores/store";
import { AssignExistingUserToTenant, ResponsiblePersonDto, UserCreationDto, UserDetailsDto, UserForAnotherTenantImplementation } from "../../../data/models/user";
import FormInputSelect from "../../../components/form/FormInputSelect";
import FormInputMultiSelect from "../../../components/form/FormInputMultiSelect";
import {
    userFormSchema,
    userUpdateFormSchema,
} from "../../../_validators/schemas/userForm.schema";
import { RoundButton } from "../../../_styles/StyledButtons";
import FormInputCheckbox from "../../../components/form/FormInputCheckbox";
import {
    getDefaultValues,
    getFullPermissions,
    getInstitutionRoles,
} from "../../../utils/helpers/userHelper";
import FormInputChipSelect from "../../../components/form/FormInputChipSelect";
import { portalLanguages } from "../../../data/static/language";
import { residencePermitOptions } from "./residencePermitOptions";
import { countries } from "../../../data/static/countries";
import FormInputDate from "../../../components/form/FormInputDate";
import "dayjs/locale/en-gb";
import { assignResponsibleUsers } from "../../../utils/helpers/clientHelper";
import { useTranslation } from "react-i18next";
import { getGenderOptions } from "../../../data/static/user";
import { getTimezoneOffset } from "../../../utils/utils";
import { surveyLanguages } from "../../../data/static/survey";
dayjs.locale("en-gb");

interface UserFormProps {
    user?: UserDetailsDto;
}

function UserRegisterForm({ user }: UserFormProps) {
    const { t } = useTranslation();
    const genderOptions = getGenderOptions(t);
    const {
        userStore: { getUsers, updateUser, register, loading, checkUser, assignUserToTenant },
        institutionStore: {
            getInstitutions,
            getResponsiblePeople,
            institutionList,
            responsibleUserList,
            selectedUserInstitution,
            removePersonByInstitutionId,
            clearResponsiblePeople,
        },
        roleStore: { getRoles, roleList },
        tenantStore: { userSettingsTenant },
        sidebarStore: { closeSidebar },
        languageStore: { getLanguages, languageList },
    } = useStore();
    const editMode = user !== undefined;
    const defaultValues = {
        ...user,
        birthdate:
            dayjs(user?.birthdate).year() < 1902
                ? null
                : dayjs(user?.birthdate).toDate(),
        gender: user?.gender ?? genderOptions[0].value,
        country: user?.country ?? "",
        city: user?.city ?? "",
        street: user?.street ?? "",
        number: user?.number ?? "",
        zip: user?.zip ?? "",
        residencePermit: user?.residenceStatus ?? "",
        notes: user?.notes ?? "",
        responsibleUsers: assignResponsibleUsers(user?.responsibleUsers || []),
        platformLanguageAlpha2: user?.platformLanguage?.alpha2 || "en",
        communicationLanguage: user?.communicationLanguage,
        userLanguages: user?.userLanguages?.map((l) => l.alpha2),
    };

    const { control, watch, trigger, setValue } = useForm<any>({
        resolver: yupResolver(editMode ? userUpdateFormSchema() : userFormSchema()),
        defaultValues: editMode && user ? defaultValues : undefined,
    });
    const isAdmin = watch("isAdmin", user?.isAdmin || false);
    const [userForTenant, setUserForTenant] = useState(
        editMode
            ? new UserForAnotherTenantImplementation(false, false, '', false)
            : new UserForAnotherTenantImplementation()
    );
    const responsiblePeopleWatcher = watch("responsibleUsers");

    useEffect(() => {
        if (userSettingsTenant) {
            getInstitutions(userSettingsTenant.id);
            getRoles(userSettingsTenant.id);
        }
        getLanguages();
    }, [
        getInstitutions,
        getRoles,
        userSettingsTenant,
        getLanguages,
        getResponsiblePeople,
        selectedUserInstitution,
        clearResponsiblePeople,
    ]);

    useEffect(() => {
        if (editMode) {
            institutionList.forEach((i) => {
                const { isChecked: isAssigned } = getDefaultValues(i, user);
                if (isAssigned) {
                    getResponsiblePeople(i.id);
                }
            });
        }

        return () => {
            clearResponsiblePeople();
        };
    }, [
        clearResponsiblePeople,
        editMode,
        getResponsiblePeople,
        institutionList,
        user,
    ]);

    const handleChange = (target: any) => {
        const institutionId = target.currentTarget.value;
        if (responsibleUserList.find((u) => u.institutionId === institutionId)) {
            if (responsiblePeopleWatcher.some((r: any) => r = typeof r === 'object')) {
                var filteredObjectData = responsiblePeopleWatcher.filter((user: ResponsiblePersonDto) => !user.responsibleUserInstitutionId?.includes(institutionId));
                setValue('responsibleUsers', filteredObjectData);
            }
            else {
                var filteredStringData = responsiblePeopleWatcher.filter((user: string) => !user.includes(institutionId));
                setValue('responsibleUsers', filteredStringData);
            }
            removePersonByInstitutionId(institutionId);
        } else {
            getResponsiblePeople(target.currentTarget.value);
        }
    };

    const handleEmailSubmit = async (event: any) => {
        event.preventDefault();

        const isEmailValid = await trigger("email");

        if (isEmailValid) {
            onEmailSubmit(control._formValues.email);
        }
    }

    const onEmailSubmit = async (email: string) => {
        let userCheckObject = await checkUser(selectedUserInstitution?.institutionId as string, email);
        if (!userCheckObject.currentTenant) {
            setUserForTenant(new UserForAnotherTenantImplementation(userCheckObject.exists, userCheckObject.currentTenant, userCheckObject.message, false));
        }
        else {
            setUserForTenant(new UserForAnotherTenantImplementation(false, true, '', true));
        }
    }

    const enableEdit = async () => {
        setUserForTenant((prevState => ({
            ...prevState,
            formDisable: true
        })));
    }

    const handleOnSubmit = async (event: any) => {
        event.preventDefault();

        const isFormValid = userForTenant.exists ? await ('email') : await trigger();

        if (isFormValid) {
            onSubmit(control._formValues);
        }
    }

    const onSubmit = async (data: any) => {
        if (institutionList.length === 0 || roleList.length === 0) {
            toast.error(
                `Please create at least one ${roleList.length === 0 ? "role" : "institution"
                }`
            );
            return;
        }

        const institutionRoles = getInstitutionRoles(data, institutionList, roleList);
        if (institutionRoles.length === 0 && !data.isAdmin) {
            toast.error("Please select at least one institution and role");
            return;
        }

        const hasZeroRoleIds = institutionRoles.some((institutionRole) => {
            if (institutionRole.roleIds.length === 0 && !data.isAdmin) {
                const institutionName = institutionList.find(
                    (i) => i.id === institutionRole.institutionId
                )?.name;
                toast.error(`Please select at least one role for ${institutionName}`);
                return true;
            }
            return false;
        });
        if (hasZeroRoleIds) {
            return;
        }

        if (institutionRoles.some((institutionRole) => institutionRole.roleIds.length > 1)) {
            const hasMultipleRestrictedRoles = roleList.some((role) => {
                if (role.name === 'Researcher' || role.name === 'Contact Manager' || role.name === 'Client') {
                    return institutionRoles.some((institutionRole) => {
                        return institutionRole.roleIds.includes(role.id)
                    })
                }
                return false;
            })

            if (hasMultipleRestrictedRoles) {
                toast.error(t("REQ_ERROR_MULTIPLE_RESTRICTED_ROLE"));
                return;
            }
        }

        const responsibleUsers: ResponsiblePersonDto[] = [];

        data.responsibleUsers.forEach((item: string | ResponsiblePersonDto) => {
            if (typeof item === 'object') {
                responsibleUsers.push({
                    responsibleUserId: item.responsibleUserId,
                    responsibleUserInstitutionId: item.responsibleUserInstitutionId
                });
            }
            else {
                responsibleUsers.push({
                    responsibleUserId: item.split(':')[0],
                    responsibleUserInstitutionId: item.split(':')[1]
                });
            }
        });

        let birthdate;
        if (data.birthdate) {
            const tzOffset = getTimezoneOffset(data.birthdate) * 60 * 60000;
            birthdate = new Date(data.birthdate.getTime() + tzOffset);
        }

        const userData: UserCreationDto = {
            id: user?.id,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            birthdate: birthdate,
            email: data.email,
            password: data.password,
            phoneNumber: data.phoneNumber,
            isAdmin: data.isAdmin,
            platformLanguageAlpha2: data.platformLanguageAlpha2,
            communicationLanguage: data.communicationLanguage,
            userLanguages: data.userLanguages,
            tenantId: userSettingsTenant?.id!,
            institutionsAssigned: institutionRoles,
            residenceStatus: data.residenceStatus,
            country: data.country,
            city: data.city,
            street: data.street,
            number: data.number as string,
            zip: data.zip as string,
            notes: data.notes,
            responsibleUsers: responsibleUsers,
        };

        const userToAssign: AssignExistingUserToTenant = {
            email: userData.email,
            isAdmin: userData.isAdmin,
            targetTenantId: userSettingsTenant?.id!,
            institutionsAssigned: institutionRoles,
            responsibleUsers: responsibleUsers,
        }

        const saveAction = editMode ? updateUser : register;

        if (userForTenant.exists) {
            await assignUserToTenant(userToAssign);
        } else {
            await saveAction(userData, selectedUserInstitution?.institutionId as string);
        }

        closeSidebar();
        toast.success(editMode ? t("TOAST_USER_UPDATE") : t("TOAST_USER_CREATE"));
        await getUsers(userData.tenantId);
    };

    return (
        <Box component="form" sx={{ mb: "1.5rem" }}>
            <Typography
                component="h1"
                variant="h5"
                sx={{ mb: 3, fontWeight: "500", fontSize: "18px" }}
            >
                {t("SETTINGS_BASIC_INFO")}
            </Typography>
            <Typography variant="caption" sx={{ display: 'flex', paddingBottom: 1 }}>Please type down the email to check its availability</Typography>
            <Grid container columnSpacing={1} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }} gap={2}>
                    <Grid item xl={9} lg={8} sm={9} xs={6} className={userForTenant.formDisable ? '' : 'disable-content'}>
                        <FormInputText name="email" control={control} label="Email *" />
                    </Grid>
                    <Grid item xl={3} lg={4} sm={3} xs={6} sx={{ alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>
                        {(loading
                            ? <CircularProgress size={25} />
                            : (editMode
                                ? (userForTenant.formDisable
                                    ? <RoundButton variant="contained" title="check" onClick={handleEmailSubmit} sx={{ width: '150px' }}>{t("USER_EMAIL_CHECK")}</RoundButton>
                                    : <RoundButton variant="contained" title="check" onClick={enableEdit} sx={{ width: '150px' }}>{t("USER_EMAIL_EDIT")}</RoundButton>
                                )
                                :
                                (userForTenant.formDisable
                                    ? <RoundButton variant="contained" title="check" onClick={handleEmailSubmit} sx={{ width: '150px' }}>{t("USER_EMAIL_CHECK")}</RoundButton>
                                    : <RoundButton variant="contained" title="check" onClick={enableEdit} sx={{ width: '150px' }}>{t("USER_EMAIL_EDIT")}</RoundButton>
                                )
                            )
                        )}
                    </Grid>
                </Grid>
                {
                    userForTenant.exists && !userForTenant.currentTenant
                        ? <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Grid item xs={12}>
                                <Typography variant="caption" color="#d78600">{t("USER_EXISTS_OTHER_TENANT")}</Typography>
                            </Grid>
                        </Grid>
                        : (
                            userForTenant.currentTenant
                                ? <Grid item xs={12} sx={{ display: 'flex' }}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" color="error">{t("USER_EXISTS_MSG")}</Typography>
                                    </Grid>
                                </Grid>
                                : null
                        )
                }
                {!userForTenant.exists ? (<>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText
                            name="firstName"
                            control={control}
                            label={`${t("USER_FIRST_NAME")} *`}
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText
                            name="lastName"
                            control={control}
                            label={`${t("USER_LAST_NAME")} *`}
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputSelect
                            name="gender"
                            label={`${t("USER_GENDER")} *`}
                            control={control}
                            options={genderOptions}
                            labelKey="label"
                            valueKey="value"
                            defaultValue={genderOptions[0].value}
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputDate name="birthdate" control={control} label={`${t("USER_BIRTHDATE")}`} disabled={userForTenant.formDisable} />
                    </Grid>
                    {!editMode && (
                        <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                            <FormInputText
                                name="password"
                                control={control}
                                label={`${t("USER_PASSWORD")} *`}
                                type="password"
                                helperText={t("PASSWORD_POLICY")}
                                isDisabled={userForTenant.formDisable}
                            />
                        </Grid>
                    )}
                    {!editMode && (
                        <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                            <FormInputText
                                name="confirmPassword"
                                control={control}
                                label={`${t("USER_CONFIRM_PASSWORD")} *`}
                                type="password"
                                isDisabled={userForTenant.formDisable}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText
                            name="phoneNumber"
                            control={control}
                            label={`${t("GENERAL_PHONE_NUMBER")} *`}
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputSelect
                            name="country"
                            control={control}
                            label={`${t("GENERAL_COUNTRY")}`}
                            options={countries}
                            labelKey="name"
                            valueKey="code"
                            defaultValue="Switzerland"
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText name="city" control={control} label={`${t("GENERAL_CITY")}`} isDisabled={userForTenant.formDisable} />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText name="street" control={control} label={`${t("GENERAL_STREET_ADDRESS")}`} isDisabled={userForTenant.formDisable} />
                    </Grid>
                    <Grid item xs={12} sm={6} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText name="number" control={control} label={`${t("GENERAL_STREET_NUMBER")}`} isDisabled={userForTenant.formDisable} />
                    </Grid>
                    <Grid item xs={12} sm={6} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText name="zip" control={control} label={`${t("GENERAL_ZIP")}`} isDisabled={userForTenant.formDisable} />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputSelect
                            name="platformLanguageAlpha2"
                            label={`${t("USER_PORTAL_LANG")} *`}
                            control={control}
                            options={portalLanguages}
                            labelKey="name"
                            valueKey="alpha2"
                            defaultValue={portalLanguages[0].alpha2}
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputSelect
                            name="communicationLanguage"
                            control={control}
                            label={`${t("USER_COMM_LANG")} *`}
                            options={surveyLanguages}
                            labelKey="name"
                            valueKey="code"
                            defaultValue={user?.communicationLanguage}
                        />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        {languageList.length > 0 && (
                            <FormInputChipSelect
                                name="userLanguages"
                                control={control}
                                label={`${t("USER_LANG")} *`}
                                options={languageList}
                                labelKey="name"
                                valueKey="alpha2"
                                search={true}
                                defaultValues={user?.userLanguages?.map(
                                    (language) => language.alpha2
                                )}
                                isDisabled={userForTenant.formDisable}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputSelect
                            name="residenceStatus"
                            label={`${t("USER_RESIDENCE_PERMIT")} *`}
                            control={control}
                            options={residencePermitOptions}
                            labelKey="name"
                            valueKey="id"
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <FormInputText
                            name="notes"
                            control={control}
                            label={`${t("GENERAL_NOTES")}`}
                            rows={3}
                            isDisabled={userForTenant.formDisable}
                        />
                    </Grid>
                </>)
                    : null}
                <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                    <Divider
                        variant="middle"
                        sx={{ display: "flex", flex: 1, margin: 0, my: 2 }}
                    />
                    <FormInputCheckbox
                        name="isAdmin"
                        control={control}
                        label={`${t("USER_MAKE_TENANT_ADMIN")}`}
                        defaultValue={user?.isAdmin ?? false}
                    />
                </Grid>
                {institutionList.length > 0 && (
                    <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                        <Divider
                            variant="middle"
                            sx={{ display: "flex", flex: 1, margin: 0, mb: 2 }}
                        />
                        <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                            {`${t("INST_ROLES")}`}
                        </Typography>
                    </Grid>
                )}
                {
                    institutionList.map((institution) => (
                        <Grid item xs={12} key={institution.id} className={userForTenant.formDisable ? 'disable-content' : ''}>
                            <FormInputMultiSelect
                                name={institution.id}
                                control={control}
                                label={institution.name}
                                defaultValue={
                                    editMode ? getDefaultValues(institution, user!) : undefined
                                }
                                options={roleList.filter(role => role.name !== "Client").map((role) => {
                                    return {
                                        value: role.id,
                                        label: role.name,
                                    };
                                })}
                                onSelect={handleChange}
                            />
                        </Grid>
                    ))}
            </Grid>
            {!isAdmin && institutionList.length > 0 && (
                <Grid item xs={12} className={userForTenant.formDisable ? 'disable-content' : ''}>
                    <Divider
                        variant="middle"
                        sx={{ display: "flex", flex: 1, margin: 0, mb: 2 }}
                    />
                    <Grid item xs={12}>
                        <FormInputChipSelect
                            name="responsibleUsers"
                            control={control}
                            label={`${t("USER_RESPONSIBLE_PEOPLE")}`}
                            options={[
                                ...responsibleUserList
                                    .filter((u) => u.id !== user?.id)
                                    .map((u) => ({
                                        userInstitutionName: `${u.firstName} ${u.lastName} (${u.institutionName})`,
                                        uniqueId: `${u.id}:${u.institutionId}`,
                                    })),
                            ]}
                            labelKey="userInstitutionName"
                            valueKey="uniqueId"
                            defaultValues={user?.responsibleUsers.map(element => {
                                return element.responsibleUserId + ':' + element.responsibleUserInstitutionId;
                            })}
                        />
                    </Grid>
                </Grid>
            )}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "end", mb: 3 }} className={userForTenant.formDisable ? 'disable-content' : ''}>
                <RoundButton
                    // type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={loading}
                    title="Submit"
                    data-testid="submit-button"
                    onClick={handleOnSubmit}
                >
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : editMode ? (
                        <Typography fontSize="13px">{t("GENERAL_UPDATE")}</Typography>
                    ) : (
                        <Typography fontSize="13px">{t("GENERAL_CREATE")}</Typography>
                    )}
                </RoundButton>
            </Box>
        </Box>
    );
}

export default observer(UserRegisterForm);

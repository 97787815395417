import { Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ConfirmDeleteDialog from "../../../../components/ConfirmDialog";
import GenericTable from '../../../../components/GenericTable';
import { StudyOverviewDTO } from '../../../../data/models/studyOverview';
import { getGenericTableStyling } from '../../../../utils/utils';
import ClientSessionActionDialog from '../../Form/ClientSessionActionDialog';
import { studyOverviewColumns } from './studyOverviewColumns';
import StudyOverviewToolbar from './StudyOverviewToolbar';
import { useStudyOverview } from './useStudyOverview';
import { useStudyOverviewActions } from './useStudyOverviewActions';
import { useNavigate } from 'react-router-dom';

const StudyOverview: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        studyOverviewList,
        handlePageChange,
        handleSearchChange,
        handleGroupChange,
        isTableLoading,
        rowCount,
        fetchStudyOverviewData
    } = useStudyOverview();

    const {
        handleStatusChange,
        onNoShow,
        displaySessionActionDialog,
    } = useStudyOverviewActions(fetchStudyOverviewData);

    return (
        <Container maxWidth="xl" sx={{ my: 5 }}>
            <Typography sx={{ fontWeight: '700', fontSize: '28px' }} marginBottom={3}>{t("COORDINATION_OVERVIEW")}</Typography>
            <GenericTable<StudyOverviewDTO>
                toolbar={<StudyOverviewToolbar onSearch={handleSearchChange} onGroupChange={handleGroupChange} />}
                columns={studyOverviewColumns(t, handleStatusChange, onNoShow, navigate)}
                rows={studyOverviewList}
                rowKey="clientId"
                props={getGenericTableStyling()}
                usePagination
                loading={isTableLoading}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCount}
            />
            {displaySessionActionDialog ? <ClientSessionActionDialog /> : <ConfirmDeleteDialog />}
        </Container>
    );
};

export default observer(StudyOverview);
